#ResetPasswordEmailScreen-Container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-ultra-light);

  user-select: none;
}

.ResetPasswordEmailScreen-buttonbox {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
#ResetPasswordEmailScreen-Section {
  min-width: 400px;
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-right: 2px solid var(--line-color);
}

#ResetPasswordEmailScreen-LoginMfaHolder {
  width: 320px;
  margin-bottom: 120px;
}

#ResetPasswordEmailScreen-LoginMfaHolder .label {
  margin-top: 10px;
}

#ResetPasswordEmailScreen-LoginMfaHolder .Button {
  width: 100%;
  margin-top: 15px;
}

.errorMessage {
  display: flex;
  align-items: center;
  padding: 10px 15px 0px 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
