#ProjectScreen {
  height: 100%;

  display: flex;
  flex-direction: column;
}

#ProjectScreen-ScrollView {
  position: relative;
  flex-grow: 1;
  width: 100%;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  overflow-x: auto;
  overflow-y: hidden;
}

#ProjectScreen-ScrollHolder {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: max-content;
}

.ProjectScreen-Section {
  position: relative;
  height: 100%;
  float: left;
  overflow: hidden;
  min-width: 200px;
}

.ProjectScreen-Divider {
  position: relative;
  height: 100%;
  width: 2px;
  background-color: var(--line-color);
  float: left;
}

.ProjectScreen-SectionHeader {
  text-align: center;
  background-color: white;
  height: 30px;
  line-height: 30px;
}

.ProjectScreen-SectionHeaderTitle {
  color: var(--text-color);
}

.ProjectScreen-SectionHeaderButton {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 6px;
  height: 18px;
  width: 18px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  background-color: var(--primary-color-dark);

  cursor: pointer;

  color: white;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
}

.ProjectScreen-SectionHeaderButton:hover {
  background-color: var(--primary-color-dark-hover);
}

.ProjectScreen-SectionList {
  position: relative;
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.ProjectScreen-SectionDisabledOverlay {
  position: absolute;
  top: 30px;
  left: 0px;
  height: calc(100% - 30px);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
}

.ProjectScreen-SectionDisabledOverlay.disabled {
  display: block;
}

.ProjectScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
  overflow: hidden;
}

.ProjectScreen-ListItem.ProjectScreen-ListItemTwoButtons {
  padding: 0px 74px 0px 10px; /* 25px + (2 * 22px) + 5px = 74px */
}

.ProjectScreen-ListItem.ProjectScreen-ListItemFiveButtons {
  padding: 0px 115px 0px 10px; /* (5 * 22px) + 5px = 115px */
}

.ProjectScreen-ListItem:hover {
  background-color: var(--primary-color-ultra-light);
}

.ProjectScreen-ListItem.ProjectScreen-ListItemSelected {
  background-color: var(--primary-color-light);
}

.ProjectScreen-SubText {
  color: var(--text-color);
  white-space: pre-wrap;
}

.ProjectScreen-ListItemButtonHolder {
  position: absolute;
  right: 15px;
  height: 30px;
  display: inline-block;
}

.ProjectScreen-ListItemButton {
  position: relative;
  height: 30px;
  width: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ProjectScreen-ListItemButton svg {
  height: 12px;
  width: 12px;
}

#ProjectScreen-ProjectSubMenu {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 2px 8px;
  width: 190px; /* Also change width in AppState.tsx > openProjectSubMenu() */
  background-color: white;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  -webkit-box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.03);
}

.ProjectScreen-ProjectSubMenuItem {
  margin: 6px 0px;
  padding: 0px 8px 0px 4px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.ProjectScreen-ProjectSubMenuItem:hover {
  background-color: var(--primary-color-ultra-light);
}

.ProjectScreen-ProjectSubMenuIcon {
  height: 30px;
  width: 25px;
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
}

.ProjectScreen-ProjectSubMenuIcon svg {
  height: 12px;
  width: 12px;
}

.ProjectScreen-ProjectSubMenuText {
  display: inline-block;
  vertical-align: top;
  margin-left: 1px;
}

#ProjectScreen-SearchSortFilterHolder .Section {
  float: left;
  margin-right: 12px;
}

#ProjectScreen-SearchSortFilterSection {
  padding: 12px 12px 12px 12px;
  display: flex;
  gap: 5px;
}

#ProjectScreen-SearchSortFilterSection #DropDown {
  width: 250px;
  margin-right: 8px;
}
#ProjectScreen-SearchSortFilterSection #TextInput-Holder {
  width: 250px;
  margin-right: 8px;
}

.ProjectScreen-MoveOverview {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  z-index: 1;

  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectScreen-MoveDialog {
  position: relative;
  height: 400px;
  width: 400px;

  background-color: white;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  -webkit-box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.03);

  display: flex;
  flex-direction: column;
}

.ProjectScreen-MoveBackButton {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-color: var(--primary-color-ultra-light);

  -webkit-transition: all 250ms ease-out;
  -moz-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;

  cursor: pointer;
}

.ProjectScreen-MoveBackButton:hover {
  background-color: var(--primary-color-light);
}

.ProjectScreen-MoveDialogTitle {
  margin-top: 9px;
  text-align: center;
  font-weight: bold;
}

.ProjectScreen-MoveDialogSubtitle {
  text-align: center;
  margin-bottom: 6px;
}

.ProjectScreen-MoveDialogProjectList {
  margin: 0px 2px;
  flex-grow: 1;
  overflow: auto;
}

.ProjectScreen-MoveDialogButtonHolders {
  margin: 0px 12px 6px 12px;
}

.ProjectScreen-MoveDialogButtonHolders .Button {
  width: calc(50% - 6px);
}

.ProjectScreen-MoveDialogButtonHolders .Button:first-of-type {
  margin-right: 12px;
}
