#ReportScreen {
  height: 100%;

  display: flex;
  flex-direction: column;
}

#ReportScreen-SelectModelSection {
  position: relative;
  flex-grow: 1;
  width: 100%;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  overflow: hidden;
}

#ReportScreen-ScrollView {
  position: relative;
  height: calc(100% - 30px);
  width: 100%;

  overflow-x: auto;
  overflow-y: hidden;
}

#ReportScreen-SelectedText {
  position: relative;
  height: 28px;
  line-height: 28px;
  padding: 0px 12px;
  overflow: hidden;
  color: var(--text-color);
}

.ReportScreen-HorizontalDivider {
  position: relative;
  height: 2px;
  width: 100%;
  background-color: var(--line-color);
}

#ReportScreen-ScrollHolder {
  position: relative;
  height: 100%;
  width: max-content;
}

.ReportScreen-Section {
  position: relative;
  height: 100%;
  float: left;
  overflow: hidden;
  min-width: 200px;
}

.ReportScreen-Divider {
  position: relative;
  height: 100%;
  width: 2px;
  background-color: var(--line-color);
  float: left;
}

.ReportScreen-SectionHeader {
  text-align: center;
  background-color: white;
  height: 30px;
  line-height: 30px;
}

.ReportScreen-SectionHeaderTitle {
  color: var(--text-color);
}

.ReportScreen-SectionHeaderTitle .CheckBox-CheckBox {
  position: absolute;
  top: 6px;
  left: 14px;
  border: 2px solid var(--text-color);
}

.ReportScreen-SectionHeaderTitle .CheckBox-CheckBox:hover {
  border: 2px solid var(--primary-color-dark-hover);
}

.ReportScreen-SectionHeaderTitle .CheckBox-CheckBox.CheckBox-Checked {
  border: 2px solid var(--primary-color-dark-hover);
}

.ReportScreen-SectionHeaderButton {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 6px;
  height: 18px;
  width: 18px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  background-color: var(--primary-color-dark);

  cursor: pointer;

  color: white;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
}

.ReportScreen-SectionHeaderButton:hover {
  background-color: var(--primary-color-dark-hover);
}

.ReportScreen-SectionList {
  position: relative;
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.ReportScreen-SectionDisabledOverlay {
  position: absolute;
  top: 30px;
  left: 0px;
  height: calc(100% - 30px);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
}

.ReportScreen-SectionDisabledOverlay.disabled {
  display: block;
}

.ReportScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 6px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.ReportScreen-ListItem:hover {
  background-color: var(--primary-color-ultra-light);
}

.ReportScreen-ListItem.ReportScreen-ListItemSelected {
  background-color: var(--primary-color-light);
}

.ReportScreen-ListItem .CheckBox-CheckBox {
  display: inline-block;
  vertical-align: top;
  margin: 6px 6px 6px 0px;
}

.ReportScreen-ListItem span {
  display: inline-block;
  vertical-align: top;
}

.ReportScreen-SubText {
  color: var(--text-color);
  white-space: pre-wrap;
}

#ReportScreen-SearchSortFilterHolder .Section {
  float: left;
  margin-right: 12px;
}

#ReportScreen-SearchSortFilterSection {
  padding: 12px 12px 12px 12px;
  display: flex;
  gap: 5px;
}

#ReportScreen-SearchSortFilterSection #DropDown {
  width: 250px;
  margin-right: 8px;
}
#ReportScreen-SearchSortFilterSection #TextInput-Holder {
  width: 250px;
  margin-right: 8px;
}

/* #ReportScreen-AnalysisHolder {
  margin: 8px 0px;
} */

/* #ReportScreen-AnalysisHolder #DropDown {
  width: 250px;
} */

#ReportScreen-ErrorMessage {
  color: var(--error-text-color);
}

.ReportScreen-ActionRow {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.ReportScreen-ActionSection {
  padding: 12px;
  border: 2px solid var(--line-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  flex-grow: 1;
}

.ReportScreen-Spacer {
  width: 12px;
}

.ReportScreen-ActionSection .Button {
  display: block;
  max-width: 250px;
}

.spinner {
  position: absolute;
  top: 7px;
  left: 14px;
  width: 15px;
  height: 15px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #5d87ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
