#BiLinksScreen {
  width: fit-content;
}

/* .BiLinksScreen-ErrorMessage {
    margin: -2px 0px 10px 4px;
    color: var(--error-text-color);
} */

.BiLinksScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.BiLinksScreen-Table td,
.BiLinksScreen-Table th {
  padding: 0px;
  /* min-width: 200px; */
  white-space: nowrap;
}

.BiLinksScreen-Table tr {
  height: 30px;
}

.BiLinksScreen-Subtable {
  border-collapse: separate;
  border-spacing: 12px 0px;
  margin: 0px -12px 0px -12px;
}

.BiLinksScreen-ScrollableText {
  display: block;
  margin: 0 auto;
  max-width: 250px;
  overflow-x: auto;
}

.BiLinksScreen-Button {
  cursor: pointer;
}

.BiLinksScreen-Table .Button {
  display: block;
  margin: 0px;
  width: 120px;
}
