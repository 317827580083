#ProjectInfoScreen {
  width: fit-content;
}

.ProjectInfoScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.ProjectInfoScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.ProjectInfoScreen-Table td,
.ProjectInfoScreen-Table th {
  padding: 0px;
  min-width: 200px;
}

.ProjectInfoScreen-Table .TextInput-Background {
  width: 300px;
}

.ProjectInfoScreen-Table #DropDown {
  width: 300px;
}

.ProjectInfoScreen-Table .ProjectInfoScreen-CellWidth {
  width: 300px;
}
