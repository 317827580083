#OverviewMapScreen {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.OverviewMapScreen-MapViewerHolder {
  flex-grow: 1;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  overflow: hidden;
}
