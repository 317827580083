#VersionModelInfoScreen {
  width: fit-content;
}

.VersionModelInfoScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.VersionModelInfoScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.VersionModelInfoScreen-Table td,
.VersionModelInfoScreen-Table th {
  padding: 0px;
}

.VersionModelInfoScreen-Table .TextInput-Background {
  width: 300px;
}

.VersionModelInfoScreen-Table #DropDown {
  width: 300px;
}

.VersionModelInfoScreen-Table a {
  color: var(--text-color);
}

.VersionModelInfoScreen-Table span {
  color: var(--text-color);
  cursor: pointer;
  text-decoration: underline;
}

.VersionModelInfoScreen-DeleteTd {
  cursor: pointer;
}
