#NewProjectScreen {
  width: fit-content;
}

.NewProjectScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.NewProjectScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.NewProjectScreen-Table td,
.NewProjectScreen-Table th {
  padding: 0px;
  min-width: 200px;
}

.NewProjectScreen-Table .TextInput-Background {
  width: 300px;
}

.NewProjectScreen-Table #DropDown {
  width: 300px;
}

.NewProjectScreen-Table .NewProjectScreen-CellWidth {
  width: 300px;
}
