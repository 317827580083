#NewModelScreen {
    width: fit-content;
}

.NewModelScreen-ErrorMessage {
    margin: -2px 0px 10px 4px;
    color: var(--error-text-color);
}

.NewModelScreen-Table {
    border-collapse: separate;
    border-spacing: 12px 8px;
    margin: -8px -12px -8px -8px;
    color: var(--text-color);
}

.NewModelScreen-Table td, .NewModelScreen-Table th {
    padding: 0px;
    min-width: 200px;
}

.NewModelScreen-Table .TextInput-Background {
    width: 300px;
}

.NewModelScreen-Table #DropDown {
    width: 300px;
}