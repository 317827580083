.CreateUserScreen-SectionList {
  position: relative;
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.CreateUserScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.CreateUserScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.CreateUserScreen-Table td,
.CreateUserScreen-Table th {
  padding: 0px;
  min-width: 300px;
  width: auto;
}

.CreateUserScreen-Table .TextInput-Background {
  width: 300px;
}

.CreateUserScreen-Table #DropDown {
  width: 300px;
}

.CreateUserRowContainer {
  display: flex;
  justify-content: center; /* Centers the items horizontally */
  align-items: center; /* Centers the items vertically */
}

.CreateUserDescBox {
  flex: 1;
  width: 400px;
}
.CreateUserActionBox {
  flex: 1;
  max-width: 50px;
}

.CreateUserScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.CreateUserScreen-ListItem.CreateUserScreen-ListItemTwoButtons {
  padding: 0px 74px 0px 10px; /* 25px + (2 * 22px) + 5px = 74px */
}

.CreateUserScreen-ListItem.CreateUserScreen-ListItemThreeButtons {
  padding: 0px 96px 0px 10px; /* 25px + (3 * 22px) + 5px = 96px */
}

.CreateUserScreen-ListItem:hover {
  background-color: var(--primary-color-ultra-light);
}

.CreateUserScreen-ListItem.CreateUserScreen-ListItemSelected {
  background-color: var(--primary-color-light);
}

.CreateUserScreen-ListItemButtonHolder {
  position: absolute;
  right: 15px;
  height: 30px;
  display: inline-block;
}

.CreateUserScreen-ListItemButton {
  position: relative;
  height: 30px;
  width: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.CreateUserScreen-ListItemButton svg {
  height: 12px;
  width: 12px;
}

.PasswordRequirements {
  font-size: 12px;
  color: var(--text-color);
}
