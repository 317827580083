#NoneScreen-Container {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color-ultra-light);

    user-select: none;
}

#NoneScreen-Section {
    min-width: 460px;
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 2px solid var(--line-color);
}

#NoneScreen-TextHolder {
    width: 400px;
}

#NoneScreen-Text {
    margin-top: 10px;
}