.DocumentationScreen-SectionList {
  position: relative;
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.DocumentationScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.DocumentationScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}
.DocumentationHeader {
  color: rgba(12, 40, 84, 0.55);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.DocumentationLink {
  color: #0c2854;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  cursor: pointer;
  margin: 0;
}

.DocumentationDescription {
  color: #7989a1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
.DocumentationScreen-Table .TextInput-Background {
  width: 300px;
}

.DocumentationScreen-Table #DropDown {
  width: 300px;
}

.DocumentationRowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DocumentationDescBox {
  flex: 1;
  width: 400px;
}
.DocumentationActionBox {
  flex: 1;
  max-width: 50px;
}

.DocumentationScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.DocumentationScreen-ListItem.DocumentationScreen-ListItemTwoButtons {
  padding: 0px 74px 0px 10px; /* 25px + (2 * 22px) + 5px = 74px */
}

.DocumentationScreen-ListItem.DocumentationScreen-ListItemThreeButtons {
  padding: 0px 96px 0px 10px; /* 25px + (3 * 22px) + 5px = 96px */
}

.DocumentationScreen-ListItem:hover {
  background-color: var(--primary-color-ultra-light);
}

.DocumentationScreen-ListItem.DocumentationScreen-ListItemSelected {
  background-color: var(--primary-color-light);
}

.DocumentationScreen-ListItemButtonHolder {
  position: absolute;
  right: 15px;
  height: 30px;
  display: inline-block;
}

.DocumentationScreen-ListItemButton {
  position: relative;
  height: 30px;
  width: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.DocumentationScreen-ListItemButton svg {
  height: 12px;
  width: 12px;
}

.Icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 5px;
}

#DocumentationScreen-GrexVersion {
  color: #7989a1;
  font-size: 14px;
}
