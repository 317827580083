.Section {
  position: relative;
  padding: 12px;
  border: 2px solid var(--line-color);
  /* min-width: 500px; */
  width: fit-content;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  margin-bottom: 12px;

  overflow: hidden;
}

.Section-DisabledOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
}

.Section-DisabledOverlay.disabled {
  display: block;
}
