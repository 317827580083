#NewVersionModelScreen {
    width: fit-content;
}

.NewVersionModelScreen-ErrorMessage {
    margin: -2px 0px 10px 4px;
    color: var(--error-text-color);
}

.NewVersionModelScreen-Table {
    border-collapse: separate;
    border-spacing: 12px 8px;
    margin: -8px -12px -8px -8px;
    color: var(--text-color);
}

.NewVersionModelScreen-Table td, .NewVersionModelScreen-Table th {
    padding: 0px;
    min-width: 200px;
}

.NewVersionModelScreen-Table .TextInput-Background {
    width: 300px;
}

.NewVersionModelScreen-Table #DropDown {
    width: 300px;
}