.TextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.TextButton p {
  position: relative;
  height: 30px;
  line-height: 30px;
  color: var(--primary-color-dark);
  padding: 0px 15px;
  text-align: center;
  cursor: pointer;
}

.TextButton p:before {
  position: absolute;
  margin: 0 auto;
  top: 100%;
  left: 0;
}

.TextButton p:hover:before {
  width: 100%;
  height: 2px;
  background-color: #000;
  content: "";
  opacity: 0.2;

  transition-duration: 0.3s;
}
