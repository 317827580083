#VersionModelNewAttachmentScreen {
  width: fit-content;
}

.VersionModelNewAttachmentScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.VersionModelNewAttachmentScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.VersionModelNewAttachmentScreen-Table td,
.VersionModelNewAttachmentScreen-Table th {
  padding: 0px;
  min-width: 200px;
}

.VersionModelNewAttachmentScreen-Table .TextInput-Background {
  width: 300px;
}

/* .VersionModelNewAttachmentScreen .FileInput {
    width: 300px;
} */

.VersionModelNewAttachmentScreen-Table #DropDown {
  width: 300px;
}
