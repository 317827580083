#GlobalParameterScreen {
  height: 100%;

  display: flex;
  flex-direction: column;
}

#GlobalParameterScreen-FilterSortHolder .Section {
  float: left;
  margin-right: 12px;
}

#GlobalParameterScreen-FilterSection {
  padding: 12px 4px 12px 12px;
}

#GlobalParameterScreen-FilterSection #DropDown {
  width: 250px;
  margin-right: 8px;
}

#GlobalParameterScreen-SelectModelSection {
  position: relative;
  flex-grow: 1;
  width: 100%;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  overflow: hidden;
}

#GlobalParameterScreen-ScrollView {
  position: relative;
  height: 100%;
  width: 100%;

  overflow-x: auto;
  overflow-y: hidden;
}

#GlobalParameterScreen-ScrollView.maginBottom {
  height: calc(100% - 30px);
}

#GlobalParameterScreen-SelectedText {
  position: relative;
  height: 28px;
  line-height: 28px;
  padding: 0px 12px;
  overflow: hidden;
  color: var(--text-color);
}

.GlobalParameterScreen-HorizontalDivider {
  position: relative;
  height: 2px;
  width: 100%;
  background-color: var(--line-color);
}

#GlobalParameterScreen-ScrollHolder {
  position: relative;
  height: 100%;
  width: max-content;
}

.GlobalParameterScreen-Section {
  position: relative;
  height: 100%;
  float: left;
  overflow: hidden;
  min-width: 200px;
}

.GlobalParameterScreen-Divider {
  position: relative;
  height: 100%;
  width: 2px;
  background-color: var(--line-color);
  float: left;
}

.GlobalParameterScreen-SectionHeader {
  text-align: center;
  background-color: white;
  height: 30px;
  line-height: 30px;
}

.GlobalParameterScreen-SectionHeaderTitle {
  color: var(--text-color);
}

.GlobalParameterScreen-SectionHeaderTitle .CheckBox-CheckBox {
  position: absolute;
  top: 6px;
  left: 14px;
  border: 2px solid var(--text-color);
}

.GlobalParameterScreen-SectionHeaderTitle .CheckBox-CheckBox:hover {
  border: 2px solid var(--primary-color-dark-hover);
}

.GlobalParameterScreen-SectionHeaderTitle .CheckBox-CheckBox.CheckBox-Checked {
  border: 2px solid var(--primary-color-dark-hover);
}

.GlobalParameterScreen-SectionHeaderButton {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 6px;
  height: 18px;
  width: 18px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  background-color: var(--primary-color-dark);

  cursor: pointer;

  color: white;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
}

.GlobalParameterScreen-SectionHeaderButton:hover {
  background-color: var(--primary-color-dark-hover);
}

.GlobalParameterScreen-SectionList {
  position: relative;
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.GlobalParameterScreen-SectionListScrollHolder {
  float: left;
}

.GlobalParameterScreen-ColumnButtonHolder {
  margin-top: 6px;
  height: 18px;
}

.GlobalParameterScreen-ColumnButton {
  margin: 0px 0px 0px 14px;
  height: 18px;
  width: 18px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  background-color: var(--primary-color-dark);

  cursor: pointer;

  color: white;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
}

.GlobalParameterScreen-ColumnButton:hover {
  background-color: var(--primary-color-dark-hover);
}

.GlobalParameterScreen-SectionDisabledOverlay {
  position: absolute;
  top: 30px;
  left: 0px;
  height: calc(100% - 30px);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
}

.GlobalParameterScreen-SectionDisabledOverlay.disabled {
  display: block;
}

.GlobalParameterScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 6px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);
}

.GlobalParameterScreen-ListItem.GlobalParameterScreen-ListItemHover {
  cursor: pointer;
}

.GlobalParameterScreen-ListItem.GlobalParameterScreen-ListItemHover:hover {
  background-color: var(--primary-color-ultra-light);
}

.GlobalParameterScreen-ListItem.GlobalParameterScreen-ListItemSelected {
  background-color: var(--primary-color-light);
}

.GlobalParameterScreen-ListItem .CheckBox-CheckBox {
  display: inline-block;
  vertical-align: top;
  margin: 6px 6px 6px 0px;
}

.GlobalParameterScreen-ListItem span {
  display: inline-block;
  vertical-align: top;
}

.GlobalParameterScreen-SubText {
  color: var(--text-color);
  white-space: pre-wrap;
}

#GlobalParameterScreen-ErrorMessage {
  color: var(--error-text-color);
}

/* .GlobalParameterScreen-SectionList {
  position: relative;
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.GlobalParameterScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.GlobalParameterScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.GlobalParameterScreen-Table td,
.GlobalParameterScreen-Table th {
  padding: 0px;
  min-width: 200px;
  width: auto;
}

.GlobalParameterScreen-Table .TextInput-Background {
  width: 300px;
}

.GlobalParameterScreen-Table #DropDown {
  width: 300px;
}

.GlobalParameterRowContainer {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.GlobalParameterDescBox {
  flex: 1;
  width: 400px;
}
.GlobalParameterActionBox {
  flex: 1;
  max-width: 50px;
}

.GlobalParameterScreen-ListItem {
  margin: 8px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  color: var(--primary-color-dark);

  cursor: pointer;
}

.GlobalParameterScreen-ListItem.GlobalParameterScreen-ListItemTwoButtons {
  padding: 0px 74px 0px 10px; 
}

.GlobalParameterScreen-ListItem.GlobalParameterScreen-ListItemThreeButtons {
  padding: 0px 96px 0px 10px; 
}

.GlobalParameterScreen-ListItem:hover {
  background-color: var(--primary-color-ultra-light);
}

.GlobalParameterScreen-ListItem.GlobalParameterScreen-ListItemSelected {
  background-color: var(--primary-color-light);
}

.GlobalParameterScreen-ListItemButtonHolder {
  position: absolute;
  right: 15px;
  height: 30px;
  display: inline-block;
}

.GlobalParameterScreen-ListItemButton {
  position: relative;
  height: 30px;
  width: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.GlobalParameterScreen-ListItemButton svg {
  height: 12px;
  width: 12px;
}

.line {
  border: 1px solid var(--line-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  margin-bottom: 12px;
  margin-top: 12px;
} */

.GlobalParameterScreen-ListItemTitle {
  display: inline-block;
  width: 120px;
  overflow: hidden;
}

#GlobalParameterScreen .TextInput-Background {
  display: inline-block;
  width: 140px;
}

.GlobalParameterScreen-ListItemDetail {
  display: inline-block;
  width: 60px;
  margin: 0px 8px;
  overflow: hidden;
}
