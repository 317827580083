/* Container for the progress bar */
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  height: 20px;
  overflow: hidden;
}

/* Fill for the progress bar */
.progress-bar-fill {
  height: 100%;
  transition: width 0.3s ease-in-out;
  background-color: var(--primary-color-dark-hover);
}

.progress-bar-fill {
  height: 100%;
  transition: width 0.3s ease-in-out;
  position: relative;
}
