#LogScreen {
    height: 100%;

    display: flex;
	flex-direction: column;
}

#LogScreen-ScrollView {
    position: relative;
    flex-grow: 1;
    width: 100%;

    border: 2px solid var(--line-color);

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    overflow: auto;
}

#LogScreen-ScrollHolder {
    position: absolute;
    top: 0px;
    left: 0px;
}

#LogScreen-Table th {
    min-width: 150px;
    padding: 10px 25px;
    color: var(--text-color);
}

#LogScreen-Table td {
    min-width: 150px;
    padding: 5px 25px;
    color: var(--primary-color-dark);
    white-space: nowrap;
}