body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color-ultra-light: #f6fafd;
  --primary-color-light: #e2eff8;
  --primary-color: #479fdb;
  --primary-color-hover: #4aa9e8;
  --primary-color-dark: #002856;
  --primary-color-dark-hover: #14366e;
  --text-color: #6a8095;
  --line-color: #e9ecf1;
  --error-text-color: #e54542;
}

.btn-primary {
  background-color: var(--primary-color-dark);
  border: none;
}

.btn-primary:hover {
  background-color: var(--primary-color-dark-hover);
}

h3 {
  color: var(--primary-color-dark);
}
h5 {
  color: var(--primary-color-dark);
}

p {
  color: var(--text-color);
}

.form-label {
  color: var(--text-color);
  margin: 0px 0px 1px 2px;
}

.form-control {
  background-color: var(--primary-color-light);
  color: var(--primary-color-dark);
  border: none;
}

.form-control:focus {
  background-color: var(--primary-color-light);
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--text-color);
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--text-color);
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--text-color);
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: var(--text-color);
}

.btn-link {
  color: var(--text-color);
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary-color);
}
