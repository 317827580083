#ModelInfoScreen {
  width: fit-content;
}

.ModelInfoScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.ModelInfoScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.ModelInfoScreen-Table td,
.ModelInfoScreen-Table th {
  padding: 0px;
  min-width: 200px;
}

.ModelInfoScreen-Table .TextInput-Background {
  width: 300px;
}

.ModelInfoScreen-Table #DropDown {
  width: 300px;
}

.ModelInfoScreen-Table td.ModelInfoScreen-ActiveButton {
  cursor: pointer;
}
