.YoutubeLink {
  color: #0c2854;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  cursor: pointer;
  margin: 0;
}
